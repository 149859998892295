import Index5 from "./pages/Index5/Index5";
import Login from "./pages/Auth/login";
import SignUp from "./pages/Auth/signup";
import PasswordForget from "./pages/Auth/password_forget";
import CookiePage from "./pages/cookie/cookie";

const routes = [
  //Auth
  { path: "/signup", component: SignUp },
  { path: "/login", component: Login },
  { path: "/password_forget", component: PasswordForget },
  { path: "/data-protection", component: CookiePage },
  { path: "/", component: Index5 },
];

export default routes;
