import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SectionTitle from "../common/section-title";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      loading: false,
      showForm: true,
      success: false,
      error: null,
    };
  }

  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (!event.target.checkValidity()) {
      return;
    }

    this.setState({ loading: true });

    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("email", this.state.email);

    try {
      const response = await fetch("/sendmail.php", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        this.setState({
          success: true,
          error: null,
          name: "",
          email: "",
        });
        this.setState({ showForm: false });
      } else {
        this.setState({
          success: false,
          error: "Form submission failed. Please try again.",
        });
        this.setState({ showForm: false });
      }
    } catch (error) {
      this.setState({
        success: false,
        error: "An error occurred. Please try again later.",
      });
      this.setState({ showForm: false });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
        <React.Fragment>
          <section className="section" id="contact">
            <Container>
              <SectionTitle
                  title="KONTAKTIEREN SIE UNS"
                  description="Bitte nehmen Sie Kontakt mit uns auf, und wir präsentieren Ihnen gerne alle Vorteile unserer Lösungen."
              />
              <Row>
                <Col lg="6">
                  <div className="mt-4 pt-4">
                    <p className="mt-4">
                      <span className="h5">Ansprechpartner Dima Belykh:</span>
                      <br />
                      <span className="text-muted d-block mt-2">
                      dima.belykh@fountainhead-it.com
                      <br />
                      06221 35 21 808
                      <br />
                      <br />
                      Römerstraße 245 <br />
                      69126 Heidelberg <br />
                      Deutschland
                    </span>
                    </p>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="custom-form mt-4 pt-4">
                    <div id="message"></div>
                    {this.state.showForm && <AvForm name="contact-form" id="contact-form" onValidSubmit={this.handleSubmit}>
                      <Row>
                        <Col lg="10">
                          <AvField
                              type="text"
                              className="form-group mt-2"
                              name="name"
                              id="name"
                              placeholder="Ihr Name*"
                              required
                              errorMessage=""
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Bitte geben Sie Ihren Namen ein",
                                },
                              }}
                              value={this.state.name}
                              onChange={this.handleNameChange}
                              readOnly={this.state.loading}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="10">
                          <AvField
                              type="email"
                              className="form-group mt-2"
                              name="email"
                              id="email"
                              placeholder="Ihre E-Mail*"
                              required
                              errorMessage=""
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Bitte geben Sie ihre E-Mail-Adresse ein",
                                },
                              }}
                              value={this.state.email}
                              onChange={this.handleEmailChange}
                              readOnly={this.state.loading}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="10" className="text-start">
                          <Button
                              className="submitBnt btn btn-primary"
                              type="submit"
                              disabled={this.state.loading}
                          >
                            {this.state.loading ? "Senden..." : "Nachricht senden"}
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>}
                    {this.state.success && (
                        <div className="success-message" style={{ marginTop: "20px" }}>Danke, dass Sie uns kontaktiert haben! Wir haben Ihre Anfrage erhalten und werden uns in Kürze bei Ihnen melden!</div>
                    )}
                    {this.state.error && (
                        <div className="error-message" style={{ marginTop: "20px" }}>Etwas ist schief gelaufen! Bitte kontaktieren Sie: dima.belykh@fountainhead-it.com</div>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </React.Fragment>
    );
  }
}

export default ContactUs;
