import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import PricingListItem from "./pricing-list-item";

class PricingBox extends Component {
  render() {
    return (
      <React.Fragment>
        <Col lg="4">
          <div className="text-center pricing-box bg-white price-active">
            {this.props.pricing.isRibbon ? (
              <div className="ribbon-box">
                <span>Popular</span>
              </div>
            ) : null}
            <h4 className="text-uppercase">{this.props.pricing.title}</h4>
            {/* <h1>${this.props.pricing.price}</h1>
            <h6 className="text-uppercase text-muted">
              Abrechnung pro {this.props.pricing.duration}
            </h6> */}
            <h6 className="text-muted">{this.props.pricing.topDescription}</h6>
            <ul className="list-unstyled">
              <PricingListItem
                title="Sonderaktion"
                description={this.props.pricing.features.discount}
              />
              <PricingListItem
                title="Preismodell"
                description={this.props.pricing.features.pricingModel}
              />
              <PricingListItem
                title="Support"
                description={this.props.pricing.features.support}
              />
              <PricingListItem
                title="Installationsgebühr"
                description={this.props.pricing.features.pricing}
              />
              <PricingListItem
                title="Daten-Importer"
                description={this.props.pricing.features.dataImporter}
              />
              <PricingListItem
                title="Domain"
                description={this.props.pricing.features.domain}
              />
              <PricingListItem
                title="Server-Standort"
                description={this.props.pricing.features.serverStandard}
              />
              <PricingListItem
                title="Einfacher Zugriff"
                description={this.props.pricing.features.access}
              />
              <PricingListItem
                title="Zusätzliche Sicherheit"
                description={this.props.pricing.features.security}
              />
            </ul>
            <h6 className="text-muted">
              {this.props.pricing.bottomDescription}
            </h6>
            <a
              href="/#contact"
              className="btn btn-primary waves-effect waves-light mt-5">
              Testen Sie kostenlos
            </a>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default PricingBox;
