import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Testimonial Box
import TestimonialBox from "./testimonial-box";

class Testimonials extends Component {
  state = {
    testimonials: [
      {
        id: 1,
        image: "assets/images/testimonials/company-1.jpg",
        name: "Sven Obertopp",
        cmpName: "EnBW",
        message:
          "“Wir haben mit Fountainhead mehrere große Projekte erfolgreich realisiert. Die Kompetenz und Zielstrebigkeit der Fountainhead Team war immer ausgezeichnet. Kommunikation und Entwicklung lief wie immer sehr effizient.”",
      },
      {
        id: 2,
        image: "assets/images/testimonials/company-2.jpg",
        name: "Stefan Phillips",
        cmpName: "AlgoMedica",
        message:
          "“Fountainhead hat uns über mehrere Jahren unterstütz und mit besten IT Dienstleistungen beliefert.”",
      },
      {
        id: 3,
        image: "assets/images/testimonials/company-3.jpg",
        name: "Melanie Schunk",
        cmpName: "BDO",
        message:
          "“Fountainhead versteht die IT Welt mit der Finanzberatung exzellent zu vereinen. Wir freuen uns auf die weiteren kreative Projekte, die wir mit Fountainhead planen. ”",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section
          className="section"
          id="testi">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Stimmen unserer Partner"
              description="Unsere Kunden sind das Herzstück unseres Geschäfts. Ihre Erfahrungen und Meinungen sind der beste Indikator für unser Engagement und unsere Professionalität. Lesen Sie, was einige unserer langjährigen Partner über die Zusammenarbeit mit Fountainhead zu sagen haben:"
            />

            <Row className="mt-5">
              {/* render testimonials box */}
              {this.state.testimonials.map((testimonial, key) => (
                <TestimonialBox
                  key={key}
                  testimonial={testimonial}
                />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Testimonials;
