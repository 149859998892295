import {Button} from "reactstrap";
import {AvForm, AvField} from "availity-reactstrap-validation";
import {useState} from "react";

import "./ContactForm.css"

const ContactForm = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!e.target.checkValidity()) {
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("email", email);

        const response = await fetch("/sendmail.php", {
            method: "POST",
            body: formData,
        });

        setLoading(false);
        if (response.ok) {
            setSuccess(true);
            setError(null);
            setEmail("");
        } else {
            setSuccess(false);
            setError("Form submission failed. Please try again.");
        }
    };

    const showInput = () => {
        return !success && !error;
    }
    return (
        <AvForm className="contact-form__container" onSubmit={handleSubmit}>
            {showInput() && <div className="contact-form__holder">
                <AvField
                    type="email"
                    className="form-group contact-form__input"
                    name="email"
                    id="email"
                    placeholder="Ihre E-Mail*"
                    required
                    value={email}
                    errorMessage=""
                    validate={{
                        required: {
                            value: true,
                            errorMessage: "Bitte geben Sie ihre E-Mail-Adresse ein",
                        },
                    }}
                    readOnly={loading}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                    className="submitBnt btn btn-primary contact-form__button"
                    type="submit"
                    disabled={loading}
                >
                    {loading ? "Senden..." : "Eine Demo anfordern"}
                </Button>
            </div>}
            {success && <div className="success-message">Danke, dass Sie uns kontaktiert haben! Wir haben Ihre Anfrage erhalten und werden uns in Kürze bei Ihnen melden!</div>}
            {error && <div className="error-message">Etwas ist schief gelaufen! Bitte kontaktieren Sie: dima.belykh@fountainhead-it.com</div>}
        </AvForm>
    );
};

export default ContactForm;
